
import MainHeader from '~/components/main-header';
import MainFooter from '~/components/main-footer';

export default {
  components: {
    MainHeader,
    MainFooter,
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.$store.commit('setMobile', window.innerWidth <= 992);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', () => {
      this.$store.commit('setMobile', window.innerWidth <= 992);
    });
  },
};
